<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import newsItem from "@/components/news-item/news-item"

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "分類頁設定",
      items: [
        {
          text: "分類頁管理",
          href: "/announcement",
        },
        {
          text: "分類頁設定",
          active: true,
        }
      ],
      newsTypePageHashId: '', // 分類頁 Id
      typeHashId: '', // 分類 Id
      mainType: null,
      subType: null,
      currentNewsList: [],
      saving: false
    };
  },
  computed: {

  },
  mounted () {
    this.newsTypePageHashId = this.$route.params.NewsTypePageHashID
    this.getTypeSetting(this.newsTypePageHashId)
    this.getTypeSettingNewsList(this.newsTypePageHashId)
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    newsItem
  },
  watch: {},
  methods: {
    // 取得單一分類頁設定
    getTypeSetting (_id) {
      const vm = this
      this.$root.apis.getNewsTypePage(_id, function (_response) {
        console.log(_response.body.data)
        const mainTypeId = _response.body.data.main_type?.id || null
        const subTypeId = _response.body.data.sub_type?.id || null
        vm.mainType = vm.$root.newsTypeList.find(_item => _item.id === mainTypeId) || null
        vm.subType = vm.mainType?.sub_types.find(_item => _item.id === subTypeId) || null
        vm.typeHashId = _response.body.data.newstype_id || null
      }, this.onApiError)
    },
    // 取得已設定的新聞列表清單
    getTypeSettingNewsList (_id) {
      const vm = this
      this.$root.apis.getNewsTypePageNewsList(_id, function (_response) {
        console.log(_response.body.data)
        vm.currentNewsList = _response.body.data
      }, this.getTypeSettingNewsListError)
    },
    // 取得新聞清單錯誤
    getTypeSettingNewsListError (_error) {
      // 若是 create 或尚未選分類 -> 不報錯
      if (this.$route.query.create || !this.typeHashId) return
      this.onApiError()
    },
    // 儲存單一分類頁設定
    onSaveTypeSetting () {
      if (!this.mainType?.id) {
        return this.$root.common.showErrorDialog('主分類為必填喔！')
      }
      const data = {
        maintype_id: this.mainType.id,
      }
      if (this.subType?.id) {
        data.subtype_id = this.subType.id
      }
      this.saving = true
      const vm = this
      this.$root.apis.updateNewsTypePage(this.newsTypePageHashId, data,
        function (_response) {
          vm.typeHashId = vm.subType?.id || vm.mainType.id
          vm.$root.common.showSingleNotify('儲存成功！')
          vm.saving = false
        },
        function (_error) {
          vm.saving = false
          vm.onApiError(_error)
        })
    },
    // 發佈單一分類頁設定
    onReleaseTypeSetting () {
      if (this.checkTypeId()) {
        const vm = this
        this.$root.common.confirmAction("發佈分類頁", "確定要發佈此分類頁？", "發佈", "再想一下",
          function (_result) {
            vm.$root.apis.releaseNewsTypePage(vm.newsTypePageHashId,
              function (_response) {
                vm.$root.common.showSingleNotify('發佈成功！')
              },
              function (_error) {
                vm.onApiError(_error)
              })
          }
        )
      }
    },
    // 刪除單一分類頁設定
    onRemoveTypeSetting () {
      const vm = this
      this.$root.common.confirmAction("刪除分類頁", "確定要刪除此分類頁？", "刪除", "再想一下",
        function (_result) {
          vm.$root.apis.deleteNewsTypePage(vm.newsTypePageHashId,
            function (_response) {
              vm.$router.push('/types-page')
            },
            
            function (_error) {
              vm.onApiError(_error)
            })
        }
      )
    },
    onApiError (_error) {
      console.error(_error)
      this.$root.common.showErrorNotify(_error.body.data)
      if (_error.body.code === '400') {
        this.$router.push('/types-page')
      }
    },
    // 檢查分類是否已設定
    checkTypeId () {
      if (!this.typeHashId) {
        this.$root.common.showErrorNotify('請先選擇分類並儲存')
        return false
      }
      return true
    },
    // 前往分類頁區域設定
    onClickTypeButton (_regionType, _isSub = false) {
      // check typeHashId
      if (this.checkTypeId()) {
        // push to route
        this.$router.push(`/types-page/edit/${this.newsTypePageHashId}/${this.typeHashId}/${_regionType}?subtype=${_isSub}`)
      }
    },
    // 新增一個新聞區塊
    createNewsSetting () {
      if (this.checkTypeId()) {
        this.$root.apis.createNewsTypePageNewsSetting(this.newsTypePageHashId, this.createNewsSettingSuccess, this.onApiError)
      }
    },
    createNewsSettingSuccess (_response) {
      const newSettingId = _response.body.data
      this.onClickTypeButton(newSettingId)
    },
    // ---- ///
    addNewOne () {
      const new_one = {
        status: false,
        link: '',
        number: '',
        select: '即時/要聞',
        text: '',
        ad_status: false
      }
      this.fromInfo.push(new_one)
    },
    deleteOne (_index) {
      this.fromInfo.splice(_index, 1)
    },


    addNewTab () {
      if (this.tabs.length < 3) {
        const new_one = {}
        this.tabs.push(new_one)
      }
    },

    addNewConfig () {
      const new_one = {}
      this.configs.push(new_one)
    }
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/types-page") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 基本設定

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label ID
                  br
                  input.form-control(
                    :value="newsTypePageHashId"
                    type="text",
                    placeholder="",
                    disabled
                  )
              .col-lg-4
                .form-group.mb-3
                  label 分類名稱
                  .row
                    .col-6
                      multiselect(
                        key="main-type-select"
                        v-model="mainType" 
                        :options="$root.newsTypeList" 
                        placeholder="選擇主分類" 
                        track-by="id"
                        label="name"
                        select-label=""
                      )
                    .col-6
                      multiselect(
                        key="sub-type-select"
                        :disabled="!mainType"
                        v-model="subType"
                        :options="(mainType && mainType.sub_types) || []"
                        placeholder="選擇次分類" 
                        track-by="id"
                        label="name"
                        select-label=""
                      )
              .col-lg-4
                .form-group.mb-3
                  label ID
                  br
                  b-button.width-sm.btn-rounded(
                    size="sm" 
                    variant="primary"
                  ) {{ subType?.id ? '子分類頁' : '分類主頁'}}

      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內容設定

        //- 子分類
        b-card-body(v-if="subType?.id")
          .types-bg 
            img(src="@/assets/images/new-use/types-small.png")
            .types-buttons 
              .type-btn-row 
                .type-btn.button-sub-type-1
                .type-btn.button-sub-type-2
                  b-button.btn-link(size="lg" variant="outline-primary" @click="onClickTypeButton(29, true)") 側邊分頁

          .types-bg( v-for="item in currentNewsList" :key="item.id")
            img(src="@/assets/images/new-use/types-news-bg.png") 
            .types-buttons
              .type-btn.button-news
                b-button.btn-link(size="lg" variant="outline-primary" @click="onClickTypeButton(item.id, true)") {{item.title}}

        //- 主分類
        b-card-body(v-else)
          .types-bg 
            img(src="@/assets/images/new-use/types-large.png")
            .types-buttons 
              .type-btn.button-1
                b-button.btn-link(size="lg" variant="outline-primary" @click="onClickTypeButton(20)") 大輪播圖
              .type-btn-row 
                .type-btn.button-2
                  b-button.btn-link(size="lg" variant="outline-primary" @click="onClickTypeButton(15)") 分類頁第一頻
                .type-btn.button-3
                  b-button.btn-link(size="lg" variant="outline-primary" @click="onClickTypeButton(16)") 側邊分頁
              .type-btn.button-4
                b-button.btn-link(size="lg" variant="outline-primary" @click="onClickTypeButton(33)") 影音新聞列表

          .types-bg( v-for="item in currentNewsList" :key="item.id")
            img(src="@/assets/images/new-use/types-news-bg.png") 
            .types-buttons
              .type-btn.button-news
                b-button.btn-link(size="lg" variant="outline-primary" @click="onClickTypeButton(item.id)") {{item.title}}


        div.d-flex.align-items-center.mt-3
          hr.dash-border
          b-button.width-lg.mx-1(variant="secondary" @click="createNewsSetting") 新增下方區塊
          hr.dash-border

        b-card-body
          .row.my-2.my-lg-4
            .col.text-center
              b-button.action-btn.width-lg.mr-1(variant="primary" @click="onSaveTypeSetting" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
              b-button.action-btn.width-lg.mr-1(variant="primary" @click="onReleaseTypeSetting") 發佈
              b-button.action-btn.width-lg(variant="danger" @click="onRemoveTypeSetting") 刪除
       



</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

.types-bg
  width: 100%
  position: relative
  img
    width: 100%
  .types-buttons 
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    .type-btn
      display: flex
      align-items: center
      justify-content: center
      transition-duration: .2s
      cursor: pointer
      .btn-link
        background: #fff
        &:hover
          background: #1e40b0
    .type-btn-row
      display: flex
      align-items: center
    .button-1
      height: 0
      padding: calc(34.5% / 2) 0
      
      &:hover
        background: rgba(255,255,255,.5)
    .button-2
      width: 73%
      height: 0
      padding: calc(49% / 2) 0
      &:hover
        background: rgba(0,0,0,.5)
    .button-3
      width: 27%
      height: 0
      padding: calc(49% / 2) 0
      &:hover
        background: rgba(0,0,0,.5)
    .button-4
      margin-top: 8%
      width: 100%
      height: 0
      padding: calc(21% / 2) 0
      &:hover
        background: rgba(0,0,0,.5)
    .button-news
      width: 100%
      height: 100%
      &:hover
        background: rgba(0,0,0,.5)
    .button-sub-type-1
      width: 73%
      height: 0
      padding: 36% 0
    .button-sub-type-2
      width: 27%
      height: 0
      padding: 36% 0
      &:hover
        background: rgba(0,0,0,.5)

      

.dash-border
  border: none
  border-top: 2px dashed #999999
  flex-grow: 1

@media (max-width: 992px)
  .action-btn
    display: block
    width: 100%
    margin: 0.5rem 0
</style>
