<template lang="pug">
b-modal#modal-choice-news(
  v-model="modalShow"
  title="選擇新聞", 
  title-class="font-18", 
  body-class="p-0" 
  size="lg"
)
  .category-list
    .category-item(
      v-for='category in $root.newsTypeList' 
      :class="{active: currentMainType.id === category.id }" 
      @click="changeMainType(category)"
    ) {{ category.name }}
  .choice-main
    .types-list
      b-button.col-auto.btn-rounded(
        size="sm" 
        :key="subType.id"
        v-for="subType in currentMainType.sub_types" 
        :variant="currentSubType.id === subType.id ? 'primary' : 'outline-primary'" 
        @click="changeSubType(subType)"
      ) {{ subType.name }}
    .news-list 
      .news-item(v-if="!newsList.length")
        span 目前沒有新聞稿件資料
      .news-item(
        v-for="news in newsList" 
        @click="setNewsItem(news)"
      )
        i.fe-check(v-if="currentNewsItem.id === news.id")
        i.fe-circle(v-else)
        img.news-img(:src="news.image")
        .news-info  
          .news-title {{ news.title  }}
          .news-number 稿號：{{ news.code  }}
  template(#modal-footer='{ ok, cancel, hide }')
    b-button(size='md' variant='danger' @click='cancel()')
      | 取消操作
    b-button(size='md' variant='success' @click='clickOk()')
      | 選擇

</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['show_status'],
  name: "NewsChoice",
  data () {
    return {
      newsList: [],
      currentNews: {},
      currentSubType: { id: "" },
      currentMainType: { id: "" },
      currentNewsItem: false,
      modalShow: false
    }
  },
  watch: {
    currentSubType (_currentSubType) {
      this.searchNewsList()
    },
    show_status (_status) {
      this.modalShow = _status
    }
  },
  created () {

  },
  methods: {
    // 獲得新聞稿件列表
    searchNewsList () {
      let vm = this
      this.$root.apis.getNewsListByEditor({
        page: 1,
        main_type: this.currentMainType.id,
        sub_type: this.currentSubType.id,
        search_source: 2,
      }, function (_response) {
        vm.newsList = _response.body.data.news
      })
    },
    // 變更主要新聞類別
    changeMainType (_type) {
      this.currentMainType = _type
      this.newsList = []
    },
    // 變更次要新聞類別
    changeSubType (_type) {
      this.currentSubType = _type
    },
    // 指定新聞稿件項目
    setNewsItem (_news) {
      this.currentNewsItem = _news
    },
    clickOk () {
      this.$emit('returnNews', this.currentNewsItem)
      this.modalShow = false
    }
  },
  components: {
    draggable
  },
}
</script>
<style lang="sass" scoped>
.category-list
  border-right: 1px solid #e5e8eb
  width: 149px
  max-height: 80vh
  overflow: auto
  .category-item
    padding: 1rem 1.5rem
    color: #6b7280e6
    cursor: pointer
    &.active
      background: #eee
.types-list
  display: flex
  gap: 5px
.choice-main
  flex: 1
  padding: 1rem 0.75rem
  max-height: 80vh
  overflow: auto
.news-list 
  
  .news-item
    padding: 1.5rem 1.25rem  1rem
    display: flex
    align-items: center
    gap: 20px
    cursor: pointer
    i
      font-size: 25px
    img.news-img
      min-width: 174px
      height: 100px
      object-fit: cover
    .news-info
      .news-title
        font-size: 17px
        margin-bottom: 10px
        
::v-deep .modal-body
  display: flex
</style>
